import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Spinner } from "evergreen-ui"
import { navigate } from "gatsby"
import { withFirebase } from "../Firebase"
import {
  isBrowser,
  isIOS, isAndroid,
} from "react-device-detect"

const Container = styled.div`
	z-index: 1;
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
  margin-top: -180px;
	img {
		margin-bottom: 0;
	}
`

const PhotoPreview = (props) => {

  const { photoLookup, gotPhotoURLHandler, gotVideoURLHandler, gotPhotoDateHandler, gotGalleryHandler, gotAccountNameHandler, firebase, gotPhotoIdHandler } = props
  const [photoUrl, setPhotoUrl] = useState(null)
  const [photoId, setPhotoId] = useState(null)
  const [videoUrl, setVideoUrl] = useState(null)
  const [accountName, setAccountName] = useState(null)
  const [deviceType, setDeviceType] = useState(null)

  useEffect(() => {
    getDeviceType()
    if (firebase) {
      getPhoto(photoLookup)
    }
  }, [firebase])


  const getDeviceType = () => {
    if (isBrowser) {
      setDeviceType("Desktop")
    } else if (isAndroid) {
      setDeviceType("Android")
    } else if (isIOS) {
      setDeviceType("iOS")
    }
  }


  const getPhoto = async (photoLookup) => {

    // Create a reference to collection
    const photoID = await firebase.db
      .collection("photoLookup")
      .doc(photoLookup)
      .get()
      .then(doc => {
        const data = doc.data()
        if (!data) {
          throw Error("Photo not found")
        }
        try {
          updateViewCount()
        } catch (e) {
          console.error(e)
        }
        return (data.photoReference.id)
      })
      .catch((error) => {
        navigate("/404")
        console.log(error)
      })

    // Create a reference to collection
    const videoID = await firebase.db
      .collection("videoLookup")
      .doc(photoLookup) // will change to just be lookup later
      .get()
      .then(doc => {
        const data = doc.data()
        if (!data) {
          throw Error("Video not found")
        }
        return (data.videoReference.id)
      })
      .catch((error) => {
        console.log(error)
      })

    const accountID = await firebase.db
      .collection("photos")
      .doc(photoID)
      .get()
      .then(doc => {

        const data = doc.data()
        setPhotoUrl(data.photoURL)
        setPhotoId(data.photoID)
        gotPhotoIdHandler(data.photoID)
        gotPhotoURLHandler(data.photoURL)
        gotPhotoDateHandler(data.createDate)

        if (data.galleryID) {
          firebase.db.collection("galleries")
            .doc(data.galleryID)
            .get()
            .then(doc => {
              const data = doc.data()
              gotGalleryHandler(data)
            })
        }

        return (data.accountID)
      })
      .catch((error) => {
        navigate("/404")
        console.log(error)
      })

    if (accountID) {

      const snapshot = await firebase.db
				.collection('presets')
				.where('accountID', '==', accountID)
				.get()
				.catch((error) => {
          navigate("/404")
					console.log('Error getting documents: ', error)
				})
        snapshot.docs.map(doc => {

          if(doc.data().isActive) {
            setAccountName(doc.data().name)
            gotAccountNameHandler(doc.data().name)
          }
        })

    }

    if (videoID) {
      firebase.db
        .collection("videos")
        .doc(videoID)
        .get()
        .then(doc => {
          const data = doc.data()
          setVideoUrl(data.videoURL)
          gotVideoURLHandler(data.videoURL)
        })
        .catch((error) => {
          navigate("/404")
          console.log(error)
        })
    }

  }

  const updateViewCount = () => {
    if (photoId) {
      firebase.db.collection(`photos/${photoId}/views`).add({
        date: new Date(),
        deviceType: deviceType,
      }).then(function(docRef) {

      })
        .catch(function(error) {
        })
    }
  }

  return (
    <>
      <Container>
        <img src={photoUrl} alt=""/>
      </Container>
      {
        !photoUrl && !accountName ? <Spinner marginTop={100}/> : null
      }

    </>
  )
}

PhotoPreview.propTypes = {
  firebase: PropTypes.any,
  photoLookup: PropTypes.string,
  gotPhotoURLHandler: PropTypes.any,
  gotVideoURLHandler: PropTypes.any,
  gotGalleryHandler: PropTypes.any,
  gotPhotoDateHandler: PropTypes.any,
  gotAccountNameHandler: PropTypes.any,
}

export default withFirebase(PhotoPreview)
