import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { IoIosArrowRoundBack } from 'react-icons/io'
import dateFormat from 'dateformat'

import { Logo } from './Logo'
import { Link } from 'gatsby'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 340px;
  background: #362165;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(27.1828px);
  /* Note: backdrop-filter has minimal browser support */

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    flex-direction: row;
  }
`

const BoxLeft = styled.div`
  display: flex;
  flex-basis: 33%;
  justify-content: center;
  padding: 1rem 0;
  
  a {
    width: 180px;
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    align-items: start;
    justify-content: start;
    padding: 2rem 5rem;
  }
`

const BoxCenter = styled.div`
  display: flex;
  flex-basis: 33%;
  justify-content: center;
  color: #fff;  
  padding: 3rem 5rem;
  text-align: center;

  h3 {
    margin: 0;
    font-size: 1rem;
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    h3 {
      margin: 0;
      font-size: 1.4rem;
    }
  }
`

const BoxRight = styled.div`
  flex-basis: 33%;
  color: #fff;  
  display: flex;
  align-self: center;

  a {
    color: #fff;
  }

  a:visited {
    color: #fff;
  }

  a:hover {
    text-decoration: none;
  }

  svg {
    vertical-align: middle;
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    display: flex;
    justify-content: flex-end;
    align-self: initial;
    padding: 3rem 5rem;
  }
`

const DownloadHeader = ({ title, galleryID, photoDate }) => {
	return (
		<Container>
			<BoxLeft>
				<Link to="/"><Logo/></Link>
			</BoxLeft>
			<BoxCenter>
				<h3>{title ? title : 'PhrameBooth' }</h3>
			</BoxCenter>
			<BoxRight>
				{
					galleryID 
						? <Link to={`/gallery/${galleryID}?d=${btoa(dateFormat(photoDate.toDate(), 'mm/dd/yyyy'))}`}><IoIosArrowRoundBack />Back to Gallery</Link>
						: <Link to="/"><IoIosArrowRoundBack />Back to Home Page</Link>
				}
			</BoxRight>
		</Container>
	)
}

DownloadHeader.propTypes = {
	title: PropTypes.string,
	photoDate: PropTypes.any,
	galleryID: PropTypes.string
}

export default DownloadHeader
