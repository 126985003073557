import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Dialog, TextInputField } from "evergreen-ui"


const Container = styled.div`
   z-index: 999 !important;
   height: 200px;


   input {
    width: 280px !important;
    height: 42px !important;
    margin-top: 18px !important;
   }
`

const InputTitle = styled.h3`
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 26px;
line-height: 172.96%;
color: #362165;
`
const FieldsContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`
const Button = styled.div`
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 172.96%;
color: #F06878;
cursor: pointer;
`
const ButtonContainer = styled.div`
width: 100%;
display: flex;
margin-top: 20px;
justify-content: flex-end;
div {
    display: flex;
    width: 200px;
}
`

const EmailModal = ({ showDialog, onClose, email, setEmail, onSend }) => {

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      isShown={showDialog}
      onCloseComplete={handleClose}
      hasHeader={false}
      hasFooter={false}
      style={{ width: "60%", marginTop: "100px" }}
    >
      {({ close }) => (
    <Container>
            <FieldsContainer>
            <InputTitle>Email a ready file to yourself</InputTitle>
                  <TextInputField
                    className="input"
                    name="email"
                    placeholder='Email Address'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
            </FieldsContainer>
            <ButtonContainer>
               <div>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={onSend}>Send</Button>
              </div>
           </ButtonContainer>
          
    </Container>
          )}
    </Dialog>
  )
}

EmailModal.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.any,
}

export default EmailModal
