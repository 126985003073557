import React from 'react'
import PropTypes from 'prop-types'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'

import Download from '../components/Download'

const PhotoPage = (props) => {	
	if (props['*'] === '') navigate('/404')

	return (		
		<>
			<Router>
				<Download path="/photo/:photoLookup" />
			</Router>
		</>
	)
}

PhotoPage.propTypes = {
	'*': PropTypes.string
}

export default PhotoPage
