import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import { AiOutlineLink, AiOutlineDownload, AiFillInstagram } from 'react-icons/ai'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import copy from 'copy-to-clipboard'
import { Alert } from 'evergreen-ui'

const Container = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 99;
  opacity: ${ props => props.visible ? '1' : '0' };
  flex-direction: column;
  width: 100%;
  height: 60px;
  background: #362165;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(27.1828px);
  margin-top: 5rem;
  transition: all 250ms;
  color: #fff;
  text-align: center;
  justify-content: center;

  a {
		color: #FFF;
		cursor: pointer;
	}

	a:hover {
    color: #FFF;
		text-decoration: none;
	}

	a:visited {
		color: #FFF;
	}
  /* Note: backdrop-filter has minimal browser support */

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    display: none;
  }
`

const CopyAlert = styled.div`
	opacity: ${props => props.show ? '1' : '0'};
	transition: all 250ms;
  margin-top: 20px;
`

const ShareFooter = ({visible, location, accountName, photoURL, videoURL}) => {

	const [copyAlert, toggleCopyAlert] = useState(false)

	const copyURL = () => {
		copy(location.href)
		toggleCopyAlert(true)

		setTimeout(() => {
			toggleCopyAlert(false)
		}, 1500)
	}
  
	return (
		<>
			<CopyAlert show={copyAlert}>
				<Alert
					appearance="card"
					intent="none"
					title="URL copied to clipboard!"
					marginBottom={32}
					onRemove={() => toggleCopyAlert(false)}
				/>
			</CopyAlert>
			<Container visible={visible}>
				<div className="container">
					<div className="row">
						<div className="col-2 offset-1">
							<FacebookShareButton url={location.href}>
								<FaFacebookF />
							</FacebookShareButton>
						</div>
						<div className="col-2">
							<a href={videoURL} download="mov" target="_blank"  rel="noopener noreferrer"><AiFillInstagram /></a>
						</div>
						<div className="col-2">
							<TwitterShareButton url={location.href} title={`${accountName} - `}>
								<FaTwitter />
							</TwitterShareButton>
						</div>
						<div className="col-2">
							<AiOutlineLink onClick={copyURL} style={{cursor:'pointer'}}/>
						</div>
						<div className="col-2">
							<a href={photoURL} download="gif" target="_blank"  rel="noopener noreferrer"><AiOutlineDownload /></a>
						</div>
					</div>
				</div>
			</Container>
		</>
	)
}

ShareFooter.propTypes = {
	visible: PropTypes.bool,
	location: PropTypes.any,
	accountName: PropTypes.string,
	photoURL: PropTypes.string,
	videoURL: PropTypes.string
}

export default ShareFooter
