import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import styled from "styled-components"
import { navigate } from "gatsby"
import { FaFacebookF, FaTwitter } from "react-icons/fa"
import { AiOutlineLink, AiOutlineDownload, AiFillInstagram } from "react-icons/ai"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import copy from "copy-to-clipboard"
import { Alert } from "evergreen-ui"
import { withFirebase } from "./Firebase"

import DownloadHeader from "./primitives/DownloadHeader"
// import DownloadFooter from './primitives/DownloadFooter'
import PhotoPreview from "../components/primitives/PhotoPreview"
import { ShareButton } from "../components/primitives/ShareButton"
import ShareFooter from "./primitives/ShareFooter"
import EmailModal from "./primitives/EmailModal"

const PhotoContainer = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 3rem;
	margin-top: -3rem;
`
const Container = styled.div`
z-index: 999 !important;
height: 100%;
margin: 0 auto;
display: flex;
align-items: center;
justify-content: center;
margin-top: 100px;
`

const ShareContainer = styled.div`
	text-align: center;
	opacity: ${props => props.visible ? "0" : "1"};
	transition-delay: 250ms;
	transition: all 750ms;
  display: flex;
  align-items: center;
  justify-content: center;

	h1 {
		font-style: normal;
		font-weight: normal;
		font-size: 24px;

		color: #402C6B;
	}

	button {
		width: 100%;
	}

	a {
		color: #FFF;
	}

	a:hover {
		text-decoration: none;
	}

	a:visited {
		color: #FFF;
	}
`

const CopyAlert = styled.div`
	opacity: ${props => props.show ? "1" : "0"};
	transition: all 250ms;
`

const Download = (props) => {

  const { photoLookup, firebase } = props

  const [copyAlert, toggleCopyAlert] = useState(false)
  const [photoURL, setPhotoURL] = useState("")
  const [photoDate, setPhotoDate] = useState("")
  const [videoURL, setVideoURL] = useState("")
  const [gallery, setGallery] = useState("")
  const [accountName, setAccountName] = useState(null)
  const [visible, setVisible] = useState(true)
  const [photoId, setPhotoId] = useState(null)
  const [showModal, setshowModal] = useState(false)
  const [email, setEmail] = useState('')
  const [base64File, setBase64File] = useState('')
  const [emailAlert, SetEmailAlert] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (photoURL === null) navigate("/404")
  }, [photoURL])

  // Hide or show the menu.
  const handleScroll = () => {

    const currentScrollPos = window.pageYOffset
    const visible = currentScrollPos === 0

    setVisible(visible)
  }

  const copyURL = () => {
    copy(props.location.href)
    toggleCopyAlert(true)

    setTimeout(() => {
      toggleCopyAlert(false)
    }, 1500)
  }

  const updateShareCount = (type) => {
    if (photoId) {
      firebase.db.collection(`photos/${photoId}/${type}`).add({
        date: new Date(),
      }).then(function (docRef) {
      })
        .catch(function (error) {
        })
    }
  }

  const handleClick = async () => {
    setshowModal(true)
    await getBase64file()
  }



  const getBase64file = () => {

    const file = videoURL
    try {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "arraybuffer";
      xhr.onload = function (event) {
        if (this.status === 200) {
          var blob = new Blob([xhr.response]);

          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {

            var base64data = reader.result;
            const data = base64data.replace(/^data:.+;base64,/, '');
            setBase64File(data)
          }
        }
      };
      xhr.open('GET', file);
      xhr.send();
    } catch (error) {
      console.log("Image Error:", error)
    }

  }

  const onSend = async () => {
    setshowModal(false)

    if (videoURL && email && base64File) {
      // firebase.functions.useFunctionsEmulator('http://localhost:5001')
      const videoShareMail = firebase.functions.httpsCallable('videoShareMail');

      try {
        await videoShareMail({ email: email, base64: base64File }).then(async (result) => {
          SetEmailAlert(true)
          setTimeout(() => {
            SetEmailAlert(false)
          }, 3000);
        }).catch((error) => { console.log(error) })
      } catch (error) {
        console.log(error);
      }
      setEmail('')
    }

    else {
      setEmail('')
    }
  }

  const handleClose = () => {
    setshowModal(false)
    setEmail('')
  }

  return (
    <div>
      <SEO title={accountName} image={photoURL} />
      <DownloadHeader title={accountName} gallery={gallery.id} photoDate={photoDate} />

      <Container>
        <EmailModal
          showDialog={showModal}
          onClose={handleClose}
          email={email}
          setEmail={setEmail}
          onSend={onSend}
        />
      </Container>

      <PhotoContainer>
        <PhotoPreview
          photoLookup={photoLookup}
          gotPhotoURLHandler={(url) => setPhotoURL(url)}
          gotPhotoDateHandler={(date) => setPhotoDate(date)}
          gotVideoURLHandler={(url) => setVideoURL(url)}
          gotGalleryHandler={(gallery) => setGallery(gallery)}
          gotAccountNameHandler={(name) => setAccountName(name)}
          gotPhotoIdHandler={(id) => setPhotoId(id)} />
      </PhotoContainer>
      <ShareFooter visible={visible} accountName={accountName} location={props.location} photoURL={photoURL}
        videoURL={videoURL} />

      <div className="container">
        <ShareContainer visible={visible} className="row">
          <div className="col-12 text-center">
            <h1>Share it:</h1>
          </div>
          <div className="col-md-10 offset-md-1 main-share-container">
            <div className="row btn-row">
              <div className="col-6 share-buttons">
                <FacebookShareButton url={props.location.href} quote="Shared from PhrameBooth"
                  hashtag={`#${gallery && gallery.hashTag ? gallery.hashTag : accountName}`}>
                  <ShareButton onClick={() => updateShareCount("facebookShare")} color='#0084FE'><FaFacebookF /> Facebook</ShareButton>
                </FacebookShareButton>
              </div>
              {
                videoURL ?
                  <div className="col-6 share-buttons">
                    <a href={false} href={false} onClick={() => handleClick(videoURL)} download="mov" target="_blank" rel="noopener noreferrer"><ShareButton
                      color='#FF496C'><AiFillInstagram /> Instagram</ShareButton></a>
                  </div> : null
              }
              <div className="col-6 share-buttons">
                <TwitterShareButton url={props.location.href}
                  title={`${accountName} - `}>
                  <ShareButton onClick={() => updateShareCount("twitterShare")}
                    color='#55ACEE'><FaTwitter /> Twitter</ShareButton>
                </TwitterShareButton>
              </div>
              <div className="col-md-4 col-6 share-buttons">
                <ShareButton color='#141414'><AiOutlineLink /> TikTok</ShareButton>
              </div>
              <div className="col-6 share-buttons">
                <ShareButton color='#402C6B' onClick={copyURL}><AiOutlineLink /> CopyLink</ShareButton>
              </div>
              {/* <div className="col-6 share-buttons">
                <a href={photoURL} download="gif" target="_blank"
                   rel="noopener noreferrer"><ShareButton color='#402C6B' onClick={() => updateShareCount("downloads")}
                                                          style={{ fontSize: ".8rem" }}><AiOutlineDownload/> Download
                  GIF</ShareButton></a>
              </div> */}
              {
                videoURL ?
                  <div className="col-6 share-buttons">
                    <a href={false} download="mov" onClick={() => handleClick(videoURL)} target="_blank" rel="noopener noreferrer"><ShareButton
                      color='#402C6B' style={{ fontSize: ".8rem" }}><AiOutlineDownload />Download</ShareButton></a>
                  </div> : null
              }
            </div>
            <CopyAlert show={copyAlert}>
              <Alert
                appearance="card"
                intent="none"
                title="URL copied to clipboard!"
                marginBottom={32}
              />
            </CopyAlert>
            <CopyAlert show={emailAlert}>
              <Alert
                appearance="card"
                intent="information"
                title="Check your email for download videos"
                marginBottom={32}
              />
            </CopyAlert>
          </div>
        </ShareContainer>
      </div>
      {/* <DownloadFooter /> */}
    </div>
  )
}

Download.propTypes = {
  firebase: PropTypes.any,
  photoLookup: PropTypes.string,
  location: PropTypes.any,
}

export default withFirebase(Download)
